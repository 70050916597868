/* eslint-disable react/jsx-no-target-blank */
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../helpers'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'

export function AsideMenuMain() {
  const intl = useIntl()

  return (
    <>
      <AsideMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/general/gen025.svg'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
      />
      {/* <div className='border m-0 text-white border-white'></div> */}
      <div className='menu-item '>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>ADMINISTRATION</span>
        </div>
      </div>
      <AsideMenuItemWithSub
        to='/crafted/pages'
        title='Ventes'
        icon='/media/icons/duotune/ecommerce/ecm002.svg'
      >
        <AsideMenuItem to='/ventes/journal-de-vente' title='Journal de vente' hasBullet={true} />
        <AsideMenuItem to='/journal-ventes/commande' title='Commande' hasBullet={true} />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to='/crafted/pages'
        title='Catalogue'
        icon='/media/icons/duotune/ecommerce/book.svg'
      >
        <AsideMenuItem to='/crafted/pages/profile/overview' title='Catalogue' hasBullet={true} />
        <AsideMenuItem to='products' title='Produit' hasBullet={true} />
        <AsideMenuItem
          to='/crafted/pages/profile/overview'
          title='Fiche technique'
          hasBullet={true}
        />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub to='/' title='Stock' icon='/media/icons/duotune/ecommerce/box.svg'>
        <AsideMenuItem to='fournisseur' title='Fournisseur' hasBullet={true} />
        <AsideMenuItem to='purchases' title='Achat' hasBullet={true} />

        <AsideMenuItem to='' title='Entrées et Sorties' hasBullet={true} />
        {/* <AsideMenuItem to='productss' title='Produit' hasBullet={true} /> */}
        <AsideMenuItem to='categorie' title='Catégorie' hasBullet={true} />
        <AsideMenuItem to='stock' title='Sys Stock' hasBullet={true} />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub to='/' title='Gestion Des Promotions' icon='/media/icons/duotune/ecommerce/box.svg'>
       
        <AsideMenuItem to='/promotions' title='Liste des  Promotions' hasBullet={true} />
      </AsideMenuItemWithSub>
      {/* <AsideMenuItem
        to='/charges'
        title='Charges'
        icon='/media/icons/duotune/ecommerce/bitcoine.svg'
      ></AsideMenuItem> */}
      <AsideMenuItemWithSub
        to='/apps/user-management/users'
        title='Utilisateurs'
        icon='/media/icons/duotune/ecommerce/users.svg'
      >
        <AsideMenuItem to='/Utilisateur' title='Utilisateur' hasBullet={true} />
        <AsideMenuItem to='/clients' title='Client' hasBullet={true} />
      </AsideMenuItemWithSub>
      <div className='border m-0 text-white  border-bottom  border-white'></div>

      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>ADMIN</span>
        </div>
      </div>
      <AsideMenuItemWithSub
        to='/apps/chat'
        title='Rapport'
        icon='/media/icons/duotune/ecommerce/charts.svg'
      >
        <AsideMenuItem to='/apps/chat/private-chat' title='Private Chat' hasBullet={true} />
        <AsideMenuItem to='/apps/chat/group-chat' title='Group Chart' hasBullet={true} />
        <AsideMenuItem to='/apps/chat/drawer-chat' title='Drawer Chart' hasBullet={true} />
      </AsideMenuItemWithSub>
      <AsideMenuItem
        to='/apps/user-management/users'
        title='Configuration'
        icon='/media/icons/duotune/ecommerce/seting.svg'
      />
    </>
  )
}
