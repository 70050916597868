import React, { useEffect, useState } from 'react';
import ComboBox from '../ComboBox';
import { useCallApi } from '../../modules/utils';

type Props = {
  data: any;
};

export const ProductItemPurchase: React.FC<Props> = ({ data }) => {
  const [products, setProducts] = useState<any[]>([]);

  const callApi = useCallApi();

  const getProducts = async () => {
    try {
      const response = await callApi({
        route: 'api/products',
        method: 'GET',
      });

      const responseData = response.data?.data || [];
      setProducts(responseData);
      console.log(responseData);
    } catch (error) {
      console.error('Error fetching product data:', error);
    }
  };

  const getName = (id: number) => {
    
    const product = products.find((product) => product.id == id);
    console.log(product?.attributes?.name )
    return product?.attributes?.name || 'jjj';
  };

  useEffect(() => {
    getProducts();
  }, []);

  return (
    <div>
      <ul id="orderitems" className="list-group mt-2">
        <li className="list-group-item active ">
          <div className="row">
            <div className="col-8">Produit</div>
            <div className="col-2 text-center">Quantitè</div>
            <div className="col-2 text-right">Prix</div>
          </div>
        </li>
        {data.attributes.purchase_items.map((purchase_item: any, index: number) => {
          return (
            <li key={index} id="288" className="list-group-item">
              <div className="row">
                <div id="order-item-name" className="col-8">
                  {getName(purchase_item.product_id)}
                  {/* <ComboBox products={products} idProduct={purchase_item.product_id} /> */}
                </div>
                <div className="col-2 d-flex flex-row justify-content-center">
                  <span className="mx-3" id="order-item-quantity">
                    {purchase_item.quantity}
                  </span>
                </div>
                <div className="col-2 text-right">
                  <span id="order-item-price">{purchase_item.purchase_price} DH</span>
                </div>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
