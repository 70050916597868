import React, { useEffect, useState } from 'react'
import { KTSVG } from '../../../_metronic/helpers'
import { useCallApi } from '../../modules/utils';
import ComboBox from '../ComboBox';

type Props={
    data:any
    deleteRow: () => void;
    setDataUpdate:any
    index:number
    calculateGrandTotal:()=>void
}
export const ItemProductPurchase:React.FC<Props> = ({data,deleteRow,setDataUpdate,index,calculateGrandTotal}) => {
  const callApi = useCallApi()
  const [subtotal, setsubtotal] = useState<number>()
  const [products, setproducts] = useState([])
  const [product, setProduct] = useState<any>()
    const [purchase_price, setPurchase_price] = useState<any>()
    const [product_price, setProductPrice] = useState<any>()


    const updatePurchaseItem = (index: number, field: string, value: any) => {
      const updatedPurchaseItems = [...setDataUpdate[0].purchase_items];
      updatedPurchaseItems[index][field] = value;
    
      setDataUpdate[1]({
        ...setDataUpdate[0],
        purchase_items: updatedPurchaseItems
      });
    };
    const getProducts = async () => {
      try {
       
        const data = await callApi({
          route: 'api/products?page[size]=10000',
          method: 'GET',
        })
  
    
  
        setproducts(data.data.data)
        console.log(data.data.data);
        
        // states.setTotalItems(data.meta.total)
        // states.setTotalPages(Math.ceil(states.totalItems / 100))
      } catch (error) {
        console.error('Error fetching product data:', error)
      }
    }
    useEffect(() => {
      setsubtotal(data.sub_total)

        getProducts()
   
    }, [])
    useEffect(() => {
      setPurchase_price(product?.attributes?.price_purchase)
      setProductPrice(product?.attributes?.product_price)
      updatePurchaseItem(index,"product_price",product?.attributes?.product_price)

     


    console.log(product?.attributes?.price_purchase);
    
    }, [product])
    useEffect(() => {
      

    }, [])
    
   
    
    
    const handleQuantityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const newQuantity = parseFloat(event.target.value);
      console.log(newQuantity);
      
      const updatedPurchaseItems = [...setDataUpdate[0].purchase_items];
      updatedPurchaseItems[index]["sub_total"] = newQuantity * purchase_price  ;
       
      setDataUpdate[1]({
        ...setDataUpdate[0],
        purchase_items: updatedPurchaseItems
      });
      setsubtotal(updatedPurchaseItems[index]["sub_total"])
    };
  
    const handlePurchasePriceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
          
      const purchase = parseFloat(event.target.value);
      // console.log(purchase);
      
      const updatedPurchaseItems = [...setDataUpdate[0].purchase_items];
      updatedPurchaseItems[index]["sub_total"] = purchase * updatedPurchaseItems[index]["quantity"]  ;
       
      setDataUpdate[1]({
        ...setDataUpdate[0],
        purchase_items: updatedPurchaseItems
      });
      setsubtotal(updatedPurchaseItems[index]["sub_total"])
    };

  return (
    <>
       <tr>
       <td>
                  <div className='d-flex align-items-center'>
                   
                    <div className='d-flex justify-content-start flex-column'>
                    <input className='d-none'type="text" value={data.id}  />
    <ComboBox  setProduct={setProduct} products={products} idProduct={data.product_id} index={index} updatePurchaseItem={updatePurchaseItem}  />
                      {/* <input className='text-dark fw-bold  d-block fs-6' type="text" value={"P1"} 
                    
                      /> */}
                    
                    </div>
                  </div>
                </td>
       <td>
                  <div className='d-flex align-items-center'>
                   
                    <div className='d-flex justify-content-start flex-column'>
                    <input className='d-none'type="text" value={data.id}  />

                      <input className='text-dark fw-bold  d-block fs-6'type="text" value={product?.attributes?.in_stock} 
                      style={{width:"15vh"}}
                    //    onChange={(e)=>{                        
                    //       setMarque(e.target.value)
                    //   }}
                      />
                    
                    </div>
                  </div>
                </td>
                  <td>
                  <div className='d-flex align-items-center'>
                   
                    <div className='d-flex justify-content-start flex-column'>
                    <input className='d-none'type="text" value={data.id}  />

                      <input className='text-dark fw-bold  d-block fs-6'type="text"value={data.quantity} 
                      style={{width:"15vh"}} 
                      onChange={(e)=>{   
                        handleQuantityChange(e)                     
                        updatePurchaseItem(index,"quantity",e.target.value)
                      }}
                      />
                    
                    </div>
                  </div>
                </td>
                <td>
                  <div className='d-flex align-items-center'>
                   
                    <div className='d-flex justify-content-start flex-column'>

                    <input
  className='text-dark fw-bold d-block fs-6'
  type="text" 
  value={purchase_price} 
  style={{width:"16vh"}}
  onChange={(e) => {
    setPurchase_price(e.target.value);
    
    handlePurchasePriceChange(e);
    updatePurchaseItem(index, "purchase_price", e.target.value);
  }}
/>


                    
                    </div>
                  </div>
                </td>
                <td>
                  <div className='d-flex align-items-center'>
                   
                    <div className='d-flex justify-content-start flex-column'>

                    <input
  className='text-dark fw-bold d-block fs-6'
  type="text" 
  value={product_price} 
  style={{width:"16vh"}}
  onChange={(e) => {
    setProductPrice(e.target.value)
    updatePurchaseItem(index, "product_price", e.target.value);
  }}
/>


                    
                    </div>
                  </div>
                </td>
                <td>
                  <div className='d-flex align-items-center'>
                   
                    <div className='d-flex justify-content-start flex-column'>

                      <input className='text-dark fw-bold  d-block fs-6'type="text" value={data.sub_total} 
                    
                    style={{width:"16vh"}}
                    //    onChange={(e)=>{                        
                    //       setMarque(e.target.value)
                    //   }}
                      />
                    
                    </div>
                  </div>
                </td>
                <td>
                  <div className='d-flex justify-content-end flex-shrink-0'>
                      
                    {/* <button
                      type={'submit'}
                      title='save'
                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1' 
                    //   onClick={()=>updateMarque(id)}
                    >
                      {
                        loading?<div className="d-flex justify-content-center">
                        <div className="spinner-border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      </div>:<KTSVG 
                        path='/media/icons/duotune/general/gen005.svg'
                          className='svg-icon-3' />
                      }
                          
                      
                    </button> */}
                    <button
                      title='drop'
                      className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm' 
                      onClick={deleteRow}
                    >
                      <KTSVG
                        path='/media/icons/duotune/general/gen027.svg'
                        className='svg-icon-3 '
                      />
                    </button>

                  </div>  
                </td>
              </tr>
    </>
  )
}
