import React, {useState, useEffect} from 'react'
import {Field, Form, Formik} from 'formik'
import {ToastContainer, toast} from 'react-toastify'
import {KTSVG} from '../../../../_metronic/helpers'
import {Loading} from '../../Loading'
import {useAuth} from '../../../modules/auth'
import {createFormData, useCallApi} from '../../../modules/utils'
interface Role {
  id: number
  attributes: {
    display_name: string
  }
}
export const ModalAddUtilisateur = () => {
  const {categories} = useAuth()
  const callApi = useCallApi()
  const [loading, setLoading] = useState(false)
  const [roles, setRoles] = useState<any>([])
  const [chejed, setchejed] = useState(false)
  const [role_id, setRole_id] = useState<any>()
  const initialValues = {
    role: '',
    first_name: '',
    last_name: '',

    status: true,
  }

  const getRoles = async () => {
    try {
      const data = await callApi({
        route: 'api/roles',
        method: 'GET',
      })
      setRoles(data.data.data)
    } catch (error: any) {
      toast.error(error.response.data.message)
    }
  }

  useEffect(() => {
    getRoles()
  }, [])

  const handleSubmit = async (values: any) => {
    setLoading(true)
    const dataToSend = {
      role_id: role_id,
      email: values.email,
      phone: values.tel,
      first_name: values.first_name,
      last_name: values.last_name,
      code: values.code,
      password: values.password,
      active: values.status ? 1 : 0,
    }
    console.log(dataToSend)

    try {
      console.log(dataToSend)
      const data = await callApi({
        route: 'api/users',
        method: 'POST',
        body: dataToSend,
      })
      toast.success('Ajouté avec succès')
      setLoading(false)
    } catch (error: any) {
      toast.error(error.response.data.message)
      setLoading(false)
    }
  }

  return (
    <>
      <div
        className='modal fade'
        id='ModalAddUtilisateur'
        role='dialog'
        aria-labelledby='exampleModalLongTitle'
        aria-hidden='true'
      >
        <div className='modal-dialog' role='document' style={{maxWidth: '61%'}}>
          <Formik
            initialValues={initialValues}
            onSubmit={(values, {resetForm}) => {
              handleSubmit(values)
            }}
          >
            {() => (
              <Form className='modal-content'>
                <div className='modal-content'>
                  <div className='modal-header ' style={{background: 'var(--kt-primary)'}}>
                    <h1 className='modal-title fs-5 text-white' id='exampleModalLabel'>
                      Nouveau utilisateur
                    </h1>
                    <button
                      type='button'
                      className='btn-close text-white'
                      data-bs-dismiss='modal'
                      aria-label='Close'
                    ></button>
                  </div>
                  <div className='modal-body'>
                    <form>
                      <div className='row mb-4'>
                        <div className='col'>
                          <div className='form-group'>
                            <label htmlFor='exampleInputEmail1' className='fw-bold'>
                              {' '}
                              Nom de famille :
                            </label>
                            <Field
                              name='first_name'
                              type='text'
                              className='form-control'
                              id='exampleInputEmail1'
                              aria-describedby='text'
                            />
                          </div>
                        </div>
                        <div className='col'>
                          <div className='form-group'>
                            <label htmlFor='exampleInputEmail1' className='fw-bold'>
                              {' '}
                              Prénom:
                            </label>
                            <Field
                              name='last_name'
                              type='text'
                              className='form-control'
                              id='exampleInputEmail1'
                              aria-describedby='text'
                            />
                          </div>
                        </div>
                      </div>
                      <div className='row mb-4'>
                        <div className='col'>
                          <div className='form-group'>
                            <label htmlFor='exampleInputEmail1' className='fw-bold'>
                              Email :
                            </label>
                            <Field
                              name='email'
                              type='email'
                              className='form-control'
                              id='exampleInputEmail1'
                              aria-describedby='text'
                            />
                          </div>
                        </div>
                        <div className='col'>
                          {' '}
                          <div className='form-group'>
                            <label htmlFor='exampleInputEmail1' className='fw-bold'>
                              {' '}
                              Mot de passe :
                            </label>
                            <Field
                              name='password'
                              type='password'
                              className='form-control'
                              id='exampleInputEmail1'
                              aria-describedby='text'
                            />
                          </div>
                        </div>
                        <div className='col'>
                          <div className='form-group'>
                            <label htmlFor='exampleInputEmail1' className='fw-bold'>
                              {' '}
                              Tel :
                            </label>
                            <Field
                              name='tel'
                              type='phone'
                              className='form-control'
                              id='exampleInputEmail1'
                              aria-describedby='text'
                            />
                          </div>
                        </div>
                      </div>
                      <div className='row mb-4'>
                        <div className='col'>
                          <div className='form-group'>
                            <label htmlFor='exampleInputEmail1' className='fw-bold'>
                              Date de Début :
                            </label>
                            <Field
                              name='date_d'
                              type='date'
                              className='form-control'
                              id='exampleInputEmail1'
                              aria-describedby='text'
                            />
                          </div>
                        </div>
                        <div className='col'>
                          {' '}
                          <div className='form-group'>
                            <label htmlFor='exampleInputEmail1' className='fw-bold'>
                              {' '}
                              Date de Fin :
                            </label>
                            <Field
                              name='date_f'
                              type='date'
                              className='form-control'
                              id='exampleInputEmail1'
                              aria-describedby='text'
                            />
                          </div>
                        </div>
                        <div className='col'>
                          <div className='form-group'>
                            <label htmlFor='exampleInputEmail1' className='fw-bold'>
                              {' '}
                              Salaire :
                            </label>
                            <Field
                              name='salaire'
                              type='number'
                              className='form-control'
                              id='exampleInputEmail1'
                              aria-describedby='text'
                            />
                          </div>
                        </div>
                      </div>
                      <div className='row  '>
                        <div className='col'>
                          <div className='form-group mb-4 fw-bold'>
                            <label htmlFor='exampleInputEmail1'> Role :</label>
                            <div className=' p-2 form-control'>
                              {roles &&
                                roles.map((role: any, index: number) => (
                                  <div className='form-check mb-2' key={index}>
                                    <input
                                      value={role.id}
                                      className='form-check-input '
                                      type='radio'
                                      name='role'
                                      onChange={(e) => setRole_id(e.target.value)}
                                      id={role.id}
                                    />
                                    <label className='form-check-label' htmlFor={role.id}>
                                      {role.attributes.display_name}
                                    </label>
                                  </div>
                                ))}
                            </div>
                          </div>
                        </div>
                    
                      </div>
                      <div className='row'>
                        <div className='col'>
                          <div className='form-group d-flex fw-bold'>
                            {' '}
                            <label htmlFor='exampleInputEmail1'>Statut :</label>
                            <div className='form-check form-switch'>
                              <Field
                                className='form-check-input'
                                type='checkbox'
                                name='status'
                                role='switch'
                                //   onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                //     const { checked } = e.target;
                                //     setchejed(checked)
                                //   }}
                                id='flexSwitchCheckDefault'
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className='modal-footer'>
                    <button type='button' className='btn btn-secondary' data-bs-dismiss='modal'>
                      Annuler
                    </button>
                    {loading ? (
                      <button type='button' className='btn btn-primary'>
                        <Loading />
                      </button>
                    ) : (
                      <button type='submit' className='btn btn-primary'>
                        valider
                      </button>
                    )}
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  )
}
