import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {
  MixedWidget11,
  ListsWidget3,
  TablesWidget5,
  StatisticsWidget5,
  ListsWidget1,
  ListsWidget6,
  TablesWidget13,
  TablesWidget12,
  TablesWidget11,
  MixedWidget4,
} from '../../../_metronic/partials/widgets'
import {useEffect, useState} from 'react'
import {useCallApi} from '../../modules/utils'
import { Link } from 'react-router-dom'
import { ModalProductVendus } from '../../components/ModalProductVendus'
import { ListsWidget10 } from '../../../_metronic/partials/widgets/lists/ListsWidget10'

const DashboardPage: React.FC = () => {
  const [todaySalles, setTodaySalles] = useState<any>(null)
  const [myDate, setMydate] = useState<any>(null)
  const callApi = useCallApi()
  useEffect(() => {
    const currentDate = new Date()
    const year = currentDate.getFullYear()
    const month = String(currentDate.getMonth() + 1).padStart(2, '0')
    const day = String(currentDate.getDate()).padStart(2, '0')
    const dated = `${year}-${month}-${day}`
    console.log(dated)
    setMydate(dated)
  }, [])
  const getSAlles = async () => {
    try {
      const {data} = await callApi({
        route: 'api/today-sales-overall-report',
        method: 'GET',
      })

      setTodaySalles(data.data)
    } catch (error) {
      console.error('Error fetching product data:', error)
    }
  }

  useEffect(() => {
    getSAlles()
  }, [])

  return (
    <>
      {/* begin::Row */}
      <div className='row g-5 g-xl-10'>
        <div className='col-xl-3'>
          <StatisticsWidget5
            href={'#'}
            className='card-xl-stretch mb-xl-8'
            svgIcon='/media/icons/duotune/finance/fin003.svg'
            color='primary'
            iconColor='white'
            title="CHIFFRE D'AFFAIRES"
            myDate={myDate}
            description={`${todaySalles && todaySalles.today_sales_total_amount} MAD`}
            titleColor='white'
            descriptionColor='white'
          />
        </div>

        <div className='col-xl-3'>
          <StatisticsWidget5
            myDate={myDate}
            className='card-xl-stretch mb-xl-8'
            svgIcon='/media/icons/duotune/ecommerce/ecm002.svg'
            color='success'
            iconColor='white'
            title='COMMANDE'
            href='/journal-ventes/commande'
            description={todaySalles && todaySalles.today_total_sales}
            titleColor='white'
            descriptionColor='white'
          />
        </div>

        <div className='col-xl-3'  
        data-bs-toggle='modal'
          data-bs-target='#modalproductvendus'>
          <StatisticsWidget5
            href={`#`}
            myDate={myDate}
            className='card-xl-stretch mb-5 mb-xl-8'
            svgIcon='/media/icons/duotune/files/fil025.svg'
            color='dark'
            iconColor='gray-100'
            title='PRODUIT VENDUS'
            description={todaySalles && todaySalles.today_sales_total_products_sold}
            titleColor='gray-100'
            descriptionColor='gray-100'
          />
          
        </div>
        <ModalProductVendus data={todaySalles && todaySalles.today_total_products_sold}/>
        <div className='col-xl-3'>
          <StatisticsWidget5
            myDate={myDate}
            href={'#'}
            className='card-xl-stretch mb-5 mb-xl-8'
            svgIcon='/media/icons/duotune/general/gen050.svg'
            color='danger'
            iconColor='white'
            title='TOTAL DES ANNULÉES'
            description={todaySalles && todaySalles.today_sales_cancelled}
            titleColor='white'
            descriptionColor='white'
          />
        </div>
      </div>
      {/* end::Row */}

      {/* begin::Row */}
      <div className='row g-5 g-xl-8'>
        {/* begin::Col */}
        <div className='col-xl-3'>
          <ListsWidget1 className='card-xl-stretch mb-xl-8' />
        </div>
        {/* end::Col */}

        {/* begin::Col */}
        <div className='col-xl-9'>
          <TablesWidget5 className='card-xl-stretch mb-5 mb-xl-8' />
        </div>
        {/* end::Col */}
      </div>
      {/* end::Row */}

      {/* begin::Row */}

      <div className='  '>
        <MixedWidget11
          className='card-xxl-stretch-50 mb-5 mb-xl-8'
          chartColor='primary'
          chartHeight='400px'
        />
      </div>

      {/* end::Row */}

      {/* begin::Row */}
      <div className='row g-5 g-xl-8'>
        {/* begin::Col */}
        <div className='col-xl-6'>
          <ListsWidget3 className=' card-xxl-stretch mb-xl-3' />
        </div>
        {/* end::Col */}

        {/* begin::Col */}
        <div className='col-xl-6'>
          <ListsWidget10 className='card-xl-stretch mb-5 mb-xl-8' />
        </div>
        {/* end::Col */}
      </div>

      {/* end::Row */}
      {/* begin::Col */}
      <div className=''>
        <ListsWidget6 className='card-xl-stretch mb-5 mb-xl-8' />
      </div>
      {/* end::Col */}
    </>
  )
}

const DashboardWrapper = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <DashboardPage />
      {/* <MixedWidget4 progress='99%' color='success' title='fff' image='' date='' className=''/> */}
    </>
  )
}

export {DashboardWrapper}
