import React, {useEffect, useState} from 'react'
import {ListProductPurhase} from '../../components/purchase/ListProductPurhase'
import {useCallApi} from '../../modules/utils'
import {useParams} from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import { useAuth } from '../../modules/auth'

export const DetailsPurchase = () => {
  // const [detailsP, setDetailP] = useState<any>()
  const [fournisseurs, setFournisseur] = useState<any[]>([])
  const {comptabilise} = useAuth()

  // const [dataUpdated, setDataUpdated] = useState<any>({
  //   id: null,
  //   date: '',
  //   warehouse_id: null,
  //   supplier_id: null,
  //   discount: 0,
  //   tax_rate: 0,
  //   purchase_items: [
  //     {
  //       discount_amount: 0,
  //       discount_type: '1',
  //       discount_value: 0,

  //       net_unit_cost: 0,

  //       tax_amount: 0,
  //       tax_type: '1',
  //       tax_value: 0,

  //       id: null,
  //       product_id: null,
  //       product_cost: 0,
  //       quantity: 0,
  //       purchase_price: 0,
  //       product_price: 0,
  //     },
  //   ],
  //   shipping: 0,

  //   grand_total: 0,
  //   paid_amount: 0,
  //   payment_type: 1,
  //   notes: '',
  //   status: 1,
  // })
  const {dataUpdated, setDataUpdated} = useAuth()

  const [loading, setLoading] = useState(true)
  const {id} = useParams()
  console.log(id)

  const callApi = useCallApi()
  const getPurchases = async () => {
    try {
      if(comptabilise==0){
        const data = await callApi({
          route: 'api/purchases/' + id,
          method: 'GET',
        })
  
        setDataUpdated({
          ...dataUpdated,
          id: data.data.data.id,
          date: data.data.data.attributes.date,
          supplier_id: data.data.data.attributes.supplier_id,
          warehouse_id: data.data.data.attributes.warehouse_id,
          discount: data.data.data.attributes.discount,
          tax_rate: data.data.data.attributes.tax_rate,
          purchase_items: data.data.data.attributes.purchase_items,
          shipping: data.data.data.attributes.shipping,
          notes: data.data.data.attributes.notes,
          grand_total: data.data.data.attributes.grand_total,
          status: data.data.data.attributes.status,
        })
      }else{
        const data = await callApi({
          route: 'api/purchases_invoiced/' + id,
          method: 'GET',
        })
  
        setDataUpdated({
          ...dataUpdated,
          id: data.data.data.id,
          date: data.data.data.attributes.date,
          supplier_id: data.data.data.attributes.supplier_id,
          warehouse_id: data.data.data.attributes.warehouse_id,
          discount: data.data.data.attributes.discount,
          tax_rate: data.data.data.attributes.tax_rate,
          purchase_items: data.data.data.attributes.purchase_items,
          shipping: data.data.data.attributes.shipping,
          notes: data.data.data.attributes.notes,
          grand_total: data.data.data.attributes.grand_total,
          status: data.data.data.attributes.status,
        })
      }
      
      // setDetailP(data.data.data)
      // states.setTotalItems(data.meta.total)
      // states.setTotalPages(Math.ceil(states.totalItems / 100))
      setLoading(false)
    } catch (error) {
      console.error('Error fetching product data:', error)
    }
  }
  const getFournisseur = async () => {
    try {
      const data = await callApi({
        route: 'api/suppliers',
        method: 'GET',
      })

      setFournisseur(data.data.data)
      // states.setTotalItems(data.meta.total)
      // states.setTotalPages(Math.ceil(states.totalItems / 100))
      setLoading(false)
    } catch (error) {
      console.error('Error fetching product data:', error)
    }
  }
  useEffect(() => {
    getPurchases()

    getFournisseur()
  }, [])
  // console.log(detailsP);
  const saveData =async() => {
    try {
      console.log(dataUpdated);
      
      const dat = await callApi({
        route: comptabilise==0?`api/purchases/`+id:`api/purchases_invoiced/`+id,
        method: 'PUT',
        body:dataUpdated ,
      })
       toast.success("modifier avec succes")
       setLoading(false)
     } catch (error:any) {
      toast.error(error.response.data.message)
      setLoading(false)
     }
    console.log(dataUpdated)
  }

  return (
    <>
    <ToastContainer/>
      <div className='row mb-3'>
        <div className='col'>
          <div className='form-group'>
            <label htmlFor='exampleInputEmail1' className='fw-bold'>
              {' '}
              Identifiant commande :
            </label>
            <input
              name='name'
              type='text'
              value={dataUpdated?.notes}
              className='form-control'
              id='exampleInputEmail1'
              aria-describedby='text'
              onChange={(e) => setDataUpdated({...dataUpdated, notes: e.target.value})}

            />
          </div>
        </div>
        <div className='col'>
          <div className='form-group'>
            <label htmlFor='exampleInputEmail1' className='fw-bold'>
              {' '}
              Nom du Fournisseur :
            </label>

            <select
              name=''
              id=''
              className='form-select'
              onChange={(e) => setDataUpdated({...dataUpdated, supplier_id: e.target.value})}
            >
              {fournisseurs &&
                fournisseurs.map((fournisseur, index) => {
                  return (
                    <option
                      key={index}
                      value={fournisseur.id}
                      selected={fournisseur.id === dataUpdated.supplier_id}
                    >
                      {fournisseur.attributes.name}
                    </option>
                  )
                })}
            </select>
          </div>
        </div>
      </div>

      <ListProductPurhase
        saveData={saveData}
        total={dataUpdated.grand_total}
        setDataUpdate={[dataUpdated, setDataUpdated]}
      />
    </>
  )
}
