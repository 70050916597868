import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
  Dispatch,
  SetStateAction,
} from 'react'
import {LayoutSplashScreen} from '../../../../_metronic/layout/core'
import {AuthModel, UserModel} from './_models'
import * as authHelper from './AuthHelpers'
import {WithChildren} from '../../../../_metronic/helpers'
import {useCallApi} from '../../utils'
import {useParams} from 'react-router-dom'

type AuthContextProps = {
  auth: AuthModel | undefined
  saveAuth: (auth: AuthModel | undefined) => void
  currentUser: UserModel | undefined
  setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>
  logout: () => void

  categories: any
  dataUpdated: any
  setDataUpdated: (object: any) => void
  comptabilise: number
  setComptabilise: (object: any) => void
  date: any
  numCmd:any,
  setNumCmd:(num:any)=>void,
  setDate: (object: any) => void
}

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => {},
  currentUser: undefined,
  setCurrentUser: () => {},
  logout: () => {},

  categories: [],
  dataUpdated: [],
  setDataUpdated: (object: any) => {},
  comptabilise: 0,
  setComptabilise: () => {},
  date: '',
  numCmd:"",
  setNumCmd:(num:any)=>{},
  setDate: (object: any) => {},
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider: FC<WithChildren> = ({children}) => {
  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth())
  const [currentUser, setCurrentUser] = useState<UserModel | undefined>()
  const {id} = useParams()
  const [comptabilise, setComptabilise] = useState(0)
  const currentDate = new Date()
  const formattedDate = currentDate.toLocaleDateString('en-CA')

  const [date, setDate] = useState(formattedDate)

  const [categories, setCategories] = useState<any>([])
  const [numCmd, setNumCmd] = useState("")
  const [dataUpdated, setDataUpdated] = useState<any>({
    id: null,
    date: '',
    warehouse_id: null,
    supplier_id: null,
    discount: 0,
    tax_rate: 0,
    purchase_items: [
      {
        discount_amount: 0,
        discount_type: '1',
        discount_value: 0,

        net_unit_cost: 0,

        tax_amount: 0,
        tax_type: '1',
        tax_value: 0,

        id: null,
        product_id: null,
        product_cost: 0,
        quantity: 0,
        purchase_price: 0,
        product_price: 0,
      },
    ],
    shipping: 0,

    grand_total: 0,
    paid_amount: 0,
    payment_type: 1,
    notes: '',
    status: 1,
  })
  const callApi = useCallApi()

  const getCategories = async () => {
    const {data} = await callApi({
      route: 'api/product-categories',
      method: 'GET',
    })
    setCategories(data.data)
    // setLoading(false)
  }
  const saveAuth = (auth: AuthModel | undefined) => {
    setAuth(auth)
    if (auth) {
      authHelper.setAuth(auth)
    } else {
      authHelper.removeAuth()
    }
  }

  const logout = () => {
    saveAuth(undefined)
    setCurrentUser(undefined)
  }
  const getPurchases = async () => {
    try {
      
      if (comptabilise == 0) {
        const data = await callApi({
          route: 'api/purchases/' + id,
          method: 'GET',
        })

        setDataUpdated({
          ...dataUpdated,
          id: data.data.data.id,
          date: data.data.data.attributes.date,
          supplier_id: data.data.data.attributes.supplier_id,
          warehouse_id: data.data.data.attributes.warehouse_id,
          discount: data.data.data.attributes.discount,
          tax_rate: data.data.data.attributes.tax_rate,
          purchase_items: data.data.data.attributes.purchase_items,
          shipping: data.data.data.attributes.shipping,
          notes: data.data.data.attributes.notes,
          grand_total: data.data.data.attributes.grand_total,
          status: data.data.data.attributes.status,
        })
      } else {
        const data = await callApi({
          route: 'api/purchases_invoiced/' + id,
          method: 'GET',
        })

        setDataUpdated({
          ...dataUpdated,
          id: data.data.data.id,
          date: data.data.data.attributes.date,
          supplier_id: data.data.data.attributes.supplier_id,
          warehouse_id: data.data.data.attributes.warehouse_id,
          discount: data.data.data.attributes.discount,
          tax_rate: data.data.data.attributes.tax_rate,
          purchase_items: data.data.data.attributes.purchase_items,
          shipping: data.data.data.attributes.shipping,
          notes: data.data.data.attributes.notes,
          grand_total: data.data.data.attributes.grand_total,
          status: data.data.data.attributes.status,
        })
      }

      // setDetailP(data.data.data)
      // states.setTotalItems(data.meta.total)
      // states.setTotalPages(Math.ceil(states.totalItems / 100))
    } catch (error) {
      console.error('Error fetching product data:', error)
    }
  }

  useEffect(() => {
    getCategories()
  }, [])
  useEffect(() => {
    console.log(id)

    getPurchases()
  }, [comptabilise])
  return (
    <AuthContext.Provider
      value={{
        auth,
        saveAuth,
        currentUser,
        setCurrentUser,
        logout,
        numCmd,
        setNumCmd,
        categories,
        dataUpdated,
        setDataUpdated,
        comptabilise,
        setComptabilise,
        date,
        setDate,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

const AuthInit: FC<WithChildren> = ({children}) => {
  const {auth, logout, setCurrentUser} = useAuth()
  const didRequest = useRef(false)
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  // We should request user by authToken (IN OUR EXAMPLE IT'S API_TOKEN) before rendering the application
  useEffect(() => {
    const requestUser = async (apiToken: any) => {
      try {
        if (!didRequest.current) {
          // const {data} = await getUserByToken(apiToken)
          // if (data) {

          setCurrentUser(auth?.data.user)
          // }
        }
      } catch (error) {
        console.error(didRequest.current)
        if (!didRequest.current) {
          logout()
        }
      } finally {
        setShowSplashScreen(false)
      }

      return () => (didRequest.current = true)
    }

    if (auth && auth?.data.user) {
      requestUser(auth)
    } else {
      logout()
      setShowSplashScreen(false)
    }

    // eslint-disable-next-line
  }, [])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

export {AuthProvider, AuthInit, useAuth}
