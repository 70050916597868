import React, {useEffect, useState} from 'react'
import {KTSVG} from '../../../_metronic/helpers'
import {useCallApi} from '../../modules/utils'
import {toast} from 'react-toastify'
import SearchComponent from '../SearchComponent'
import {ItemPurchase} from './ItemPurchase'
import {useAuth} from '../../modules/auth'
import {Link} from 'react-router-dom'
import {FilterPucrhase} from './FilterPucrhase'
import {compile} from 'sass'

type Props = {
  states: any
  // deleteVille:(id:number)=>void
}
export const ListPurchase: React.FC<Props> = ({states}) => {
  const [purchases, setPurchases] = useState<any[]>([])
  const [loading, setLoading] = useState(true)
  const [itemsSelected, setItemsSelected] = useState<number>(0)
  const [checkedProductIds, setCheckedProductIds] = useState<any>([]) // State to store checked product IDs
  const [searchTerm, setSearchTerm] = useState<string>('')
  const {currentUser, comptabilise} = useAuth()
  const [filterPurchase, setFilterPurchase] = useState<any>([])
  console.log(currentUser)

  const callApi = useCallApi()
  const getPurchases = async () => {
    try {
      const {data} = await callApi({
        route:
          comptabilise == 1
            ? 'api/purchases_invoiced?page[size]=10&page[number]=' + states.currentPage
            : 'api/purchases?page[size]=10&page[number]=' + states.currentPage,
        method: 'GET',
      })

      const initialpurchases = data.data.map((item: any) => ({
        ...item,
        checked: false,
      }))

      setPurchases(initialpurchases)
      states.setTotalItems(data.meta.total)
      states.setTotalPages(Math.ceil(states.totalItems / 100))

      setLoading(false)
    } catch (error) {
      console.error('Error fetching product data:', error)
    }
  }

  const handleHeaderCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked
    const updatedpurchases = filterPurchase.map((product: any) => ({
      ...product,
      checked,
    }))
    if (checked) {
      const checkedProductIds = updatedpurchases.map((product: any) => product.id)
      // Store the checkedProductIds in your table or wherever you need
      setItemsSelected(checkedProductIds.length)
      console.log(checkedProductIds)
    } else {
      setItemsSelected(0)
    }
    const checkedIds = updatedpurchases
      .filter((product: any) => product.checked)
      .map((product: any) => product.id)
    setCheckedProductIds(checkedIds)
    setFilterPurchase(updatedpurchases)
  }

  const handleProductCheckboxChange =
    (productId: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const checked = event.target.checked
      const updatedpurchases = filterPurchase.map((product: any) =>
        product.id === productId ? {...product, checked} : product
      )
      setFilterPurchase(updatedpurchases)
      const checkedIds = updatedpurchases
        .filter((product: any) => product.checked)
        .map((product: any) => product.id)
      setCheckedProductIds(checkedIds)
      setItemsSelected(checkedIds.length)
    }
  const deleteProduct = async (ids: number[]) => {
    if (window.confirm('Voulez-vous vraiment supprimer les achats selectionnées !')) {

    ids.map(async (id: number) => {
        const filteredposts = filterPurchase.filter((product: any) => product.id !== id)
        setFilterPurchase(filteredposts)
        setItemsSelected(0)

        toast.success('supprimée avec succés')
        const {data} = await callApi({
          route: comptabilise == 1 ? 'api/purchases/' + id : 'api/purchases/' + id,
          method: 'DELETE',
        })
     
    }) }
  }
  console.log(checkedProductIds)

  const getPurchaseByStatus = async(status: string) => {
    setLoading(true)
    try {
      const {data} = await callApi({
        route:
          comptabilise == 1
            ? 'api/purchases_invoiced?page[size]=10&page[number]=1&status='+status+'&filter[notes]=' + searchTerm
            : 'api/purchases?page[size]=10&page[number]=1&status='+status+'&filter[notes]=' + searchTerm,
            method: 'GET',
      })

      const initialpurchases = data.data.map((item: any) => ({
        ...item,
        checked: false,
      }))

      setPurchases(initialpurchases)
      states.setTotalItems(data.meta.total)
      states.setTotalPages(Math.ceil(states.totalItems / 100))

      setLoading(false)
    } catch (error) {
      console.error('Error fetching product data:', error)
    }
   
  }

  const getPurchaseByName = async () => {
    try {
      setLoading(true)

      const {data} = await callApi({
        route:
          comptabilise == 1
            ? 'api/purchases_invoiced?page[size]=10&page[number]=1&filter[notes]=' + searchTerm
            : 'api/purchases?page[size]=10&page[number]=1&filter[notes]=' + searchTerm,
            method: 'GET',
      })

      const initialpurchases = data.data.map((item: any) => ({
        ...item,
        checked: false,
      }))

      setPurchases(initialpurchases)
      states.setTotalItems(data.meta.total)
      states.setTotalPages(Math.ceil(states.totalItems / 100))

      setLoading(false)
    } catch (error) {
      console.error('Error fetching product data:', error)
    }
  }

  useEffect(() => {
    getPurchases()
    setFilterPurchase(purchases)
  }, [states.currentPage, comptabilise])
  useEffect(() => {
    getPurchaseByName()
  }, [searchTerm])

  console.log(purchases)

  return (
    <>
      <div className={`card }`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>
              Achats
              <SearchComponent
                placeholder={'Recherche Achat'}
                searchQuery={[searchTerm, setSearchTerm]}
              />
            </span>
          </h3>
          <div
            className='card-toolbar'
            data-bs-toggle='tooltip'
            data-bs-placement='top'
            data-bs-trigger='hover'
            title='Click to add a user'
          >
            <>
              {/* begin::Filter Button */}
              <FilterPucrhase getPurchaseByStatus={getPurchaseByStatus} />
              {/* end::SubMenu */}
            </>
            {itemsSelected != 0 ? (
              <div className='d-flex justify-content-end align-items-center'>
                <div className='fw-bolder me-5'>
                  <span className='me-2'>{itemsSelected}</span> Selected
                </div>
                <button
                  type='button'
                  className='btn  btn-sm btn-danger'
                  onClick={() => {
                    deleteProduct(checkedProductIds)
                  }}
                >
                  Supprimer les sélectionnés
                </button>
              </div>
            ) : (
              <>
                <Link
                  to={'/purchase/add'}
                  className='btn btn-sm btn-primary '
                  // data-bs-toggle='modal'
                  // data-bs-target='#modaladdproduct'
                >
                  <KTSVG path='/media/icons/duotune/general/gen035.svg' className='svg-icon-2' />
                  Nouvelle commande
                </Link>
                {/* <ModalAddProduct /> */}
              </>
            )}
          </div>
        </div>
        {loading ? (
          <div className='d-flex justify-content-center'>
            <div className='spinner-border' role='status'>
              <span className='visually-hidden'>Loading...</span>
            </div>
          </div>
        ) : (
          <div className='card-body py-3'>
            {/* begin::Table container */}
            <div className='table-responsive'>
              {/* begin::Table */}
              <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                {/* begin::Table head */}
                <thead>
                  <tr className='fw-bold text-muted'>
                    <th>
                      <div className='form-check form-check-custom form-check-solid mx-5'>
                        <input
                          type='checkbox'
                          className='form-check-input'
                          checked={filterPurchase.every((product: any) => product.checked)}
                          onChange={handleHeaderCheckboxChange}
                        />
                      </div>
                    </th>
                    <th className='min-w-100px '>N°</th>
                    {/* <th className="min-w-150px">Pays</th> */}
                    <th className='min-w-150px '>Libellé</th>
                    <th className='min-w-150px text-center'> Fournisseur</th>
                    <th className='min-w-150px text-center'>DateCreation</th>
                    <th className='min-w-100px text-center'>Total</th>

                    <th className='min-w-100px text-end'>Actions</th>
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                  {purchases.map((product: any, index: any) => {
                    return (
                      <ItemPurchase
                        // deleteProduct={deleteProduct}
                        key={index}
                        data={product}
                        handleProductCheckboxChange={handleProductCheckboxChange}
                      />
                    )
                  })}
                </tbody>
                <tfoot></tfoot>
                {/* end::Table body */}
              </table>
              {/* <Pagination count={totalPages} page={currentPage} color="primary" onChange={handlePageChange}  className="d-flex justify-content-center"/> */}
              {/* end::Table */}
            </div>
            {/* end::Table container */}
          </div>
        )}
        {/* end::Header */}
        {/* begin::Body */}

        {/* begin::Body */}
      </div>
    </>
  )
}
